// @import "../../styles/variable.module.scss";

[data-theme=dark]{
   
        // .darkImgs{
        //   display: block !important;
        //   margin-left: 178px;
        // }
        .text{
            color: #f1f1f1;
            font-size: 17px;
            // text-align: justify;
            padding: 43px 10px;
            line-height: 35px;
        }
        .textHeading{
            color: #f1f1f1;
            font-size: 17px;
            text-align: justify;
            padding: 6px 0px;
            line-height: 28px;
        }
        .title{
          font-size: 25px !important;
          color: #f3d400;
        }
        .main_title{
          font-size: 40px !important;
          color: #f3d400;
        }
        .titlerow{
          padding:20px 20px;
        }
    
  }

  // @media screen and (max-width: 768px) {
  //   .darkImgs{
  //     display: block !important;
  //     margin-left: 33px;
  //   }
   
  //   .text{
  //     padding: 14px 3px;
  // }
  // }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .darkImgs{
      display: block !important;
      margin-left: 33px !important;
    }
    .text{
      padding: 14px 3px;
  }
  .titlerow{
    padding:0px 0px !important  ;
  }
  }
  @media only screen and (max-width: 480px) {
    /* For mobile phones: */
    .darkImgs{
      display: block !important;
      margin-left: 33px !important;
    }
    .titlerow{
      padding:0px 0px !important;
    }
    .text{
      padding: 14px 3px;
  }
  }